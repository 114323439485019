import { lb, as, yp, de } from "../../maps";
import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
const Defi = ({ theme, connected }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const request = async () => {
      if (connected) {
        navigate("/verify");
      }
    };

    request();
  }, [connected]);
  return (
    <body
      className="w-[100%] flex flex-col items-center justify-center min-h-[100vh] bg-white text-center
        outfit dark:bg-[#003f54] px-[20px] lg:pl-[200px] dark:text-white text-[#001f29] pt-[10px] lg:pt-[120px] pb-[40px]"
    >
      <h1 className="text-[30px] font-[400]">DeFi Integrations</h1>
      <h1 className="text-[16px] font-[300] pb-[30px]">
        Swap, supply, borrow and earn yield on the following partner protocols
      </h1>

      <section className="lg:w-[900px] w-[100%] flex flex-col items-center rounded-[1rem] dark:bg-[#002430] bg-[#e8f5ff] py-[15px]">
        <h1 className="text-[18px]">Lending and borrowing</h1>

        <section className="flex flex-col items-start w-[100%] px-[20px]">
          {lb.map((us, id) => (
            <a
              href={us.link}
              key={id}
              className={` ${
                us.id === 1
                  ? "bg-[#d7edfd] dark:bg-[#003f54]"
                  : "bg-white dark:bg-[#001016] "
              } border-solid border-[1px] border-[#00b3ed] text-black dark:text-white
         text-[16px] font-[500] w-[100%] mt-[20px] rounded-[1rem] py-[15px] flex flex-row items-center justify-between
      px-[30px] cursor-auto`}
            >
              <div className="flex flex-row items-center">
                <img src={us.icon} className="w-[52px] rounded-[50%]" />
                <h1 className="pl-[10px] text-[22px] font-[700]">{us.name}</h1>
              </div>

              <a
                href={us.link}
                className="bg-transparent border-solid border-[1px] border-[#00b3ed] text-black dark:text-white
         text-[16px] font-[400] hover:bg-[#00b3ed] rounded-[.75rem] py-[5px] hidden lg:flex flex-row items-center justify-center
      hover:text-white w-[148px]"
              >
                Launch App
              </a>
            </a>
          ))}
        </section>
      </section>

      <section className="lg:w-[900px] w-[100%] flex flex-col items-center rounded-[1rem] dark:bg-[#002430] bg-[#e8f5ff] py-[15px] mt-[15px]">
        <h1 className="text-[18px]">Automated strategies</h1>

        <section className="flex flex-col items-start w-[100%] px-[20px]">
          {as.map((us, id) => (
            <a
              href={us.link}
              key={id}
              className=" bg-white dark:bg-[#001016] border-solid border-[1px] border-[#00b3ed] text-black dark:text-white
         text-[16px] font-[500] w-[100%] mt-[20px] rounded-[1rem] py-[15px] flex flex-row items-center justify-between
      px-[30px] cursor-auto"
            >
              <div className="flex flex-row items-center">
                <img src={us.icon} className="w-[52px] rounded-[50%]" />
                <h1 className="pl-[10px] text-[22px] font-[700]">{us.name}</h1>
              </div>

              <a
                href={us.link}
                className="bg-transparent border-solid border-[1px] border-[#00b3ed] text-black dark:text-white
         text-[16px] font-[400] hover:bg-[#00b3ed] rounded-[.75rem] py-[5px] hidden lg:flex flex-row items-center justify-center
      hover:text-white w-[148px]"
              >
                Launch App
              </a>
            </a>
          ))}
        </section>
      </section>

      <section className="lg:w-[900px] w-[100%] flex flex-col items-center rounded-[1rem] dark:bg-[#002430] bg-[#e8f5ff] py-[15px] mt-[15px]">
        <h1 className="text-[18px]">Decentralized exchanges</h1>

        <section className="flex flex-col items-start w-[100%] px-[20px]">
          {de.map((us, id) => (
            <a
              href={us.link}
              key={id}
              className=" bg-white dark:bg-[#001016] border-solid border-[1px] border-[#00b3ed] text-black dark:text-white
         text-[16px] font-[500] w-[100%] mt-[20px] rounded-[1rem] py-[15px] flex flex-row items-center justify-between
      px-[30px] cursor-auto"
            >
              <div className="flex flex-row items-center">
                <img src={us.icon} className="w-[52px] rounded-[50%]" />
                <h1 className="pl-[10px] text-[22px] font-[700]">{us.name}</h1>
              </div>

              <a
                href={us.link}
                className="bg-transparent border-solid border-[1px] border-[#00b3ed] text-black dark:text-white
         text-[16px] font-[400] hover:bg-[#00b3ed] rounded-[.75rem] py-[5px] hidden lg:flex flex-row items-center justify-center
      hover:text-white w-[148px]"
              >
                Launch App
              </a>
            </a>
          ))}
        </section>
      </section>

      <section className="lg:w-[900px] w-[100%] flex flex-col items-center rounded-[1rem] dark:bg-[#002430] bg-[#e8f5ff] py-[15px] mt-[15px]">
        <h1 className="text-[18px]">Yield protocols</h1>

        <section className="flex flex-col items-start w-[100%] px-[20px]">
          {yp.map((us, id) => (
            <a
              href={us.link}
              key={id}
              className=" bg-white dark:bg-[#001016] border-solid border-[1px] border-[#00b3ed] text-black dark:text-white
         text-[16px] font-[500] w-[100%] mt-[20px] rounded-[1rem] py-[15px] flex flex-row items-center justify-between
      px-[30px] cursor-auto"
            >
              <div className="flex flex-row items-center">
                <img src={us.icon} className="w-[52px] rounded-[50%]" />
                <h1 className="pl-[10px] text-[22px] font-[700]">{us.name}</h1>
              </div>

              <a
                href={us.link}
                className="bg-transparent border-solid border-[1px] border-[#00b3ed] text-black dark:text-white
         text-[16px] font-[400] hover:bg-[#00b3ed] rounded-[.75rem] py-[5px] hidden lg:flex flex-row items-center justify-center
      hover:text-white w-[148px]"
              >
                Launch App
              </a>
            </a>
          ))}
        </section>
      </section>
    </body>
  );
};

export default Defi;
