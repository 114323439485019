
const yp = [
    {
        id: 1,
        icon: "./images/y1.svg",
        name: "Ribbon Finance",
        link: "https://app.ribbon.finance/v2/theta-vault/T-sAVAX-C"
    },
    {
        id: 2,
        icon: "./images/y2.png",
        name: "Vector Finance",
        link: "https://vectorfinance.io/stake"
    },
    {
        id: 3,
        icon: "./images/y3.png",
        name: "Yield Yak",
        link: "https://yieldyak.com/farms"
    },

]

export default yp