import { Modal, ModalContent, ModalBody, Drawer } from "@chakra-ui/react";
import { wallets, what, mobilewallets } from "./maps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

const Connect = ({ connectWallet, setConnectWallet, onClose }) => {
  return (
    <Modal isOpen={connectWallet} onClose={connectWallet}>
      <ModalContent className="flex flex-col items-center w-[100%] min-h-[100vh] z-50 fixed">
        <div
          className="bg-black bg-opacity-50 w-[100%] min-h-[100vh] absolute z-40"
          onClick={() => {
            setConnectWallet(false);
          }}
        ></div>

        <ModalBody className="flex z-50 flex-col items-center justify-start pt-[150px] min-h-[100vh] w-[100%]">
          <div
            className="bg-[#1a1b1f] hidden lg:w-[720px] w-[100%] h-[504px] z-50 lg:flex flex-row items-start 
              rounded-[24px] text-white border-solid border-[1px] border-[#ffffff14]"
          >
            <section
              className="border-solid border-r-[1px] border-[#ffffff14] h-[100%]
          w-[35%] p-[20px]"
            >
              <h1 className="text-[18px] font-[600]">Connect a Wallet</h1>
              <h1
                className="text-[14px] font-[600] pt-[20px]
            text-[#ffffff99]"
              >
                Popular
              </h1>

              <section className="flex flex-col items-start pt-[10px]">
                {wallets.map((us, id) => (
                  <div
                    key={id}
                    className="flex flex-row items-center justify-start hover:bg-[#2e3036] 
                        w-[100%] p-[5px] rounded-[12px] mb-[5px] cursor-pointer "
                  >
                    <img src={us.icon} className="w-[28px] rounded-[5px]" />
                    <h1 className="text-white font-[700] text-[16px] pl-[10px]">
                      {us.title}
                    </h1>
                  </div>
                ))}
              </section>
            </section>
            <section className="w-[65%] h-[100%] p-[20px] flex flex-col items-center justify-start">
              <div className="flex flex-row items-center justify-end w-[100%]">
                <button
                  className="bg-[#ffffff14] rounded-[50%] h-[28px] w-[28px] 
                border-solid border-[1px] border-[#ffffff14] flex flex-col items-center justify-center"
                  onClick={() => {
                    setConnectWallet(false);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faX}
                    className="text-[#9dac97] text-[12px]"
                  />
                </button>
              </div>
              <h1 className="text-[18px] font-[600] pt-[30px] pb-[60px]">
                What is a wallet?
              </h1>
              <section className="flex flex-col items-center justify-center w-[100%] pb-[20px]">
                {what.map((us, id) => (
                  <div
                    key={id}
                    className="flex flex-row items-center w-[80%] mb-[20px]"
                  >
                    <img src={us.icon} className="w-[48px]" />
                    <div className="flex flex-col items-start pl-[10px]">
                      <h1 className="text-[14px] font-[700]">{us.title}</h1>
                      <h1 className="text-[14px] font-[500] text-[#ffffff99] ">
                        {us.details}
                      </h1>
                    </div>
                  </div>
                ))}
              </section>

              <button
                className="text-[16px] font-[700] bg-[#3898ff] rounded-[9999px]
             h-[28px] flex flex-row items-center justify-center px-[10px]
             hover:scale-[1.05]"
              >
                Get a wallet
              </button>
              <a
                className="text-[#3898ff] text-[14px] font-[700] pt-[15px]"
                href=""
              >
                Learn more
              </a>
            </section>
          </div>

          <Drawer
            placement="bottom"
            isOpen={connectWallet}
            onClose={onClose}
            className="fixed bottom-0 lg:hidden flex-col items-center justify-center z-50 bg-red-500 pb-[20px] max-h-[90vh]"
          >
            <section
              className="flex lg:hidden text-white w-[100%]
          rounded-t-[15px] flex flex-col items-center z-50 fixed bottom-0"
            >
              <section className="bg-[#25262a] rounded-t-[20px] p-[20px] w-[100%]">
                <div className="flex flex-row items-center justify-between w-[100%]">
                  <div className="w-[25%]"></div>
                  <h1
                    className="text-white text-[18px] font-[700] w-[50%]
                flex flex-row items-center justify-center"
                  >
                    Connect a Wallet
                  </h1>
                  <div className="w-[25%] flex flex-row items-end justify-end">
                    <button
                      className="bg-[#ffffff14] rounded-[50%] h-[28px] w-[28px] 
                flex flex-col items-center justify-center"
                      onClick={() => {
                        setConnectWallet(false);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faX}
                        className="text-[#9dac97] text-[12px]"
                      />
                    </button>
                  </div>
                </div>

                <section className="flex flex-row items-center justify-around w-[100%] pt-[20px]">
                  {mobilewallets.map((us, id) => (
                    <div key={id} className="flex flex-col items-center">
                      <img src={us.icon} className="w-[60px] rounded-[12px] " />
                      <h1 className="text-white text-[13px] font-[400] pt-[5px]">
                        {us.title}
                      </h1>
                    </div>
                  ))}
                </section>
              </section>

              <section
                className="p-[30px] flex flex-col items-center justify-center bg-[#1a1b1f]
            border-solid border-t-[1px] border-[#ffffff14]"
              >
                <h1 className="text-white text-[18px] font-[700]">
                  What is a Wallet?
                </h1>
                <h1
                  className="text-[#ffffff99] text-[16px] font-[400] text-center pb-[20px]
                "
                >
                  A wallet is used to send, receive, store, and display digital
                  assets. It’s also a new way to log in, without needing to
                  create new accounts and passwords on every website.
                </h1>

                <div className="flex flex-row items-center">
                  <button
                    className="hover:scale-[1.025] border-solid border-[1px] border-[#ffffff14]
                    px-[24px] h-[43px] flex flex-row items-center justify-center rounded-[9999px] text-[#3898ff]
                    font-[700] mr-[10px]"
                  >
                    Get a wallet
                  </button>
                  <a
                    href="https://learn.rainbow.me/understanding-web3"
                    className="hover:scale-[1.025] border-solid border-[1px] border-[#ffffff14]
                    px-[24px] h-[43px] flex flex-row items-center justify-center rounded-[9999px] text-[#3898ff]
                    font-[700]"
                  >
                    Learn more
                  </a>
                </div>
              </section>
            </section>
          </Drawer>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Connect;
