

const what = [
    {
        id: 1,
        icon: "./images/p1.png",
        title: "A Home for your Digital Assets",
        details: "Wallets are used to send, receive, store, and display digital assets like Ethereum and NFTs."
    },
    {
        id: 2,
        icon: "./images/p2.png",
        title: "A New Way to Log In",
        details: "Instead of creating new accounts and passwords on every website, just connect your wallet."
    },
];

export default what