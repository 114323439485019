const v = [
  {
    id: 1,
    blackIcon: <img src="./images/v1.png" className="w-[131px]" />,
    whiteIcon: <img src="./images/bv1.png" className="w-[131px]" />,
    link: "https://nirvanalabs.io/nirvana-cloud",
  },
  {
    id: 2,
    blackIcon: <img src="./images/v2.svg" className="w-[123px]" />,
    whiteIcon: <img src="./images/bv2.svg" className="w-[123px]" />,
    link: "https://chainstack.com/protocols/avalanche/",
  },
  {
    id: 3,
    blackIcon: <img src="./images/v3.svg" className="w-[95px]" />,
    whiteIcon: <img src="./images/bv3.svg" className="w-[95px]" />,
    link: "https://www.zeeve.io/blockchain-protocols/deploy-avalanche-node/",
  },
  {
    id: 4,
    blackIcon: <img src="./images/v4.svg" className="w-[46px]" />,
    whiteIcon: <img src="./images/bv4.svg" className="w-[46px]" />,
    link: "https://aws.amazon.com/marketplace/pp/prodview-nd6wgi2bhhslg",
  },
  {
    id: 5,
    blackIcon: <img src="./images/v5.svg" className="w-[126px]" />,
    whiteIcon: <img src="./images/bv5.svg" className="w-[126px]" />,
    link: "https://www.alibabacloud.com/blog/avax-naas-node-as-a-service-on-alibaba-cloud_599481",
  },
  {
    id: 6,
    blackIcon: <img src="./images/v6.svg" className="w-[134px]" />,
    whiteIcon: <img src="./images/bv6.svg" className="w-[134px]" />,
    link: "https://support.avax.network/en/articles/7016802-how-do-i-launch-an-avalanche-validator-using-tencent-cloud",
  },
];

export default v;
