const morePages2 = [
    {
      id: 1,
      name: "Add QI",
      icon: "./images/lightQi.svg",
      darkIcon: "./images/darkQi.svg",
      link: "",
    },
    {
      id: 2,
      name: "Add sVAX",
      icon: "./images/sVax.svg",
      darkIcon: "./images/darkSvax.svg",
      link: "",
    },
  ];

  export default morePages2