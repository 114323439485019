const morePages = [
    {
      id: 1,
      name: "DeFi Integrations",
      icon: "./images/defi.svg",
      darkIcon: "./images/darkdefi.svg",
      link: "/#/defi",
    },
    {
      id: 2,
      name: "Lend and borrow",
      icon: "./images/lend.svg",
      darkIcon: "./images/darkLend.svg",
      link: "https://app.ben-qi.fi",
    },
    {
      id: 3,
      name: "Analytics",
      icon: "./images/A.svg",
      darkIcon: "./images/darkA.svg",
      link: "https://community.chaoslabs.xyz/benqi",
    },
    {
      id: 4,
      name: "BENQI on dappOS",
      icon: "./images/daps.svg",
      darkIcon: "./images/darkDaps.svg",
      link: "/#/",
    },
/*    {
      id: 5,
      name: "Add QI",
      icon: "./images/lightqi.svg",
      darkIcon: "./images/darkQi.svg",
      link: "",
    },
    {
      id: 6,
      name: "Add sVAX",
      icon: "./images/sVax.svg",
      darkIcon: "./images/darkSvax.svg",
      link: "",
    },*/
  ];

  export default morePages