import React, { useState, createContext, useContext, useMemo } from "react";

const GatekeeperContext = createContext({
  isLoading: false,
  setIsLoading: () => {},
});

export const GatekeeperProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const contextValue = useMemo(
    () => ({
      isLoading,
      setIsLoading,
    }),
    [isLoading, setIsLoading]
  );

  return (
    <GatekeeperContext.Provider value={contextValue}>
      {children}
    </GatekeeperContext.Provider>
  );
};

export function useGatekeeper() {
  return useContext(GatekeeperContext);
}
