
const b = [
    {
        id: 1,
        name: "AVAX",
        icon: "https://app.benqi.fi/svgs/tokens/avax.svg",
        tokenAddress: 'nativeToken',
        cgId: 'avalanche-2'
    },
    {
        id: 2,
        name: "sAVAX",
        icon: "https://app.benqi.fi/svgs/tokens/savax.svg",
        tokenAddress: '0x2b2C81e08f1Af8835a78Bb2A90AE924ACE0eA4bE',
        cgId: 'benqi-liquid-staked-avax'
    },
    {
        id: 3,
        name: "USDt",
        icon: "https://app.benqi.fi/svgs/tokens/usdtn.svg",
        tokenAddress: '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
        cgId: 'bridged-usdt'
    },
    {
        id: 4,
        name: "USDC",
        icon: "https://app.benqi.fi/svgs/tokens/usdcn.svg",
        tokenAddress: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
        cgId: 'bridged-usdc'
    },
    {
        id: 5,
        name: "DAI.e",
        icon: "https://app.benqi.fi/svgs/tokens/dai.svg",
        tokenAddress: '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
        cgId: 'dai'
    },
    {
        id: 6,
        name: "BTC.b",
        icon: "https://app.benqi.fi/svgs/tokens/btcb.svg",
        tokenAddress: '0x152b9d0FdC40C096757F570A51E494bd4b943E50',
        cgId: 'bitcoin-avalanche-bridged-btc-b'
    },
    {
        id: 7,
        name: "WETH.e",
        icon: "https://app.benqi.fi/svgs/tokens/eth.svg",
        tokenAddress: '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
        cgId: 'weth-plenty-bridge-65aa5342-507c-4f67-8634-1f4376ffdf9a'
    },
    {
        id: 8,
        name: "LINK.e",
        icon: "https://app.benqi.fi/svgs/tokens/link.svg",
        tokenAddress: '0x5947BB275c521040051D82396192181b413227A3',
        cgId: 'bridged-chainlink-lightlink'
    },
    /*{
        id: 9,
        name: "USDC.e",
        icon: "https://app.benqi.fi/svgs/tokens/usdc.svg",
        cgId: 'usd-coin-avalanche-bridged-usdc-e'
    },
    {
        id: 10,
        name: "USDT.e",
        icon: "https://app.benqi.fi/svgs/tokens/usdt.svg",
        cgId: 'usd-coin-avalanche-bridged-usdc-e'
    },*/
]

export default b