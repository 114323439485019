
const lb = [
    {
        id: 1,
        icon: "./images/b.svg",
        name: "BENQI",
        link: "/"
    },
    {
        id: 2,
        icon: "./images/l2.svg",
        name: "AAVE",
        link: "https://app.aave.com/reserve-overview/?underlyingAsset=0x2b2c81e08f1af8835a78bb2a90ae924ace0ea4be&marketName=proto_avalanche_v3"
    },
    {
        id: 3,
        icon: "./images/l3.svg",
        name: "Nereus Finance",
        link: "https://nxusd.nereus.finance/pool/13"
    },
]

export default lb