
const mobilewallets = [
    {
        id: 1,
        icon: "./images/w2.png",
        title: "WalletConnect"
    },
    {
        id: 2,
        icon: "./images/w3.png",
        title: "MetaMask"
    },
    {
        id: 3,
        icon: "./images/w4.png",
        title: "Coinbase"
    },
]

export default mobilewallets