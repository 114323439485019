
const de = [
    {
        id: 1,
        icon: "./images/de1.png",
        name: "Dexalot",
        link: "https://app.dexalot.com/trade/sAVAX-AVAX"
    },
    {
        id: 2,
        icon: "./images/de2.svg",
        name: "Trader Joe",
        link: "https://traderjoexyz.com/trade?inputCurrency=AVAX&outputCurrency=0x2b2c81e08f1af8835a78bb2a90ae924ace0ea4be"
    },
    {
        id: 3,
        icon: "./images/de3.svg",
        name: "Balancer",
        link: "https://app.balancer.fi/#/avalanche/pool/0xfd2620c9cfcec7d152467633b3b0ca338d3d78cc00000000000000000000001c"
    },
    {
        id: 4,
        icon: "./images/de4.svg",
        name: "Wombat",
        link: "https://app.wombat.exchange/pool?chain=avalanche"
    },
    {
        id: 5,
        icon: "./images/de5.svg",
        name: "Cables Finance",
        link: "https://app.cables.finance/"
    },
    {
        id: 6,
        icon: "./images/de6.png",
        name: "Platypus",
        link: "https://app.platypus.finance/pool?pool_group=alt&pool_sub_group=USD%2CAVAX%2CBTC"
    },
    {
        id: 7,
        icon: "./images/de7.svg",
        name: "KyberSwap",
        link: "https://kyberswap.com/pools/avalanche?search=savax&tab=classic"
    },
    {
        id: 8,
        icon: "./images/de8.svg",
        name: "Pangolin",
        link: "https://info.pangolin.exchange/#/pair/0x4E9A38F05c38106C1cf5c145Df24959ec50ff70D"
    },
    {
        id: 9,
        icon: "./images/de9.png",
        name: "OpenOcean",
        link: "https://app.openocean.finance/CLASSIC#/AVAX/SAVAX/AVAX"
    },

]

export default de