
const wallets = [
    {
        id: 1,
        icon: "./images/w1.png",
        title: "Rabby"
    },
    {
        id: 2,
        icon: "./images/w2.png",
        title: "WalletConnect"
    },
    {
        id: 3,
        icon: "./images/w3.png",
        title: "MetaMask"
    },
    {
        id: 4,
        icon: "./images/w4.png",
        title: "Coinbase Wallet"
    },
    {
        id: 5,
        icon: "./images/w5.png",
        title: "Phantom"
    },
]

export default wallets