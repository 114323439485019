import "./App.css";
import { HashRouter, Routes, Route } from "react-router-dom";
import { StakeLayout, Defi, Vote, Staking } from "./Components";
import { useState } from "react";
import { useDisclosure } from "@chakra-ui/react";

import { GatekeeperProvider } from "./context/Gatekeeper";
import { useGatekeeper } from "./context/Gatekeeper";
import useWeb3Actions from "./Components/hooks/useWeb3Actions";

import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";

import { avalanche } from "./utils/chains";

import { useWeb3ModalAccount, useDisconnect } from "@web3modal/ethers5/react";
import Verify from "./Components/Verify";
const projectId = process.env.REACT_APP_PROJECT_ID;

const metadata = {
  name: "Benqi",
  description:
    "Liquid Staking and Algorithmic Liquidity Market Protocol on Avalanche.",
  url: "https://staking.benqi.fi/",
  icons: ["https://staking.ben-qi.fi/favicon.svg"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata, defaultChainId: 43114 }),
  chains: [avalanche],
  projectId,
  theme: "light",
  excludeWalletIds: [
    "a797aa35c0fadbfc1a53e7f675162ed5226968b44a19ee3d24385c64d1d3c393",
  ],
});

function App() {
  const [theme, setTheme] = useState("dark");
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { onClose } = useDisclosure();
  const { isLoading, setIsLoading } = useGatekeeper();
  // const [isLoading, setIsLoading] = useState(false);
  const { action } = useWeb3Actions({
    isLoading,
    setIsLoading,
    address,
    chainId,
    isConnected,
  });
  const [connectWallet, setConnectWallet] = useState(false);
  const [showSide, setShowSide] = useState(false);
  const [connected, setConnected] = useState(true);
  const [breakdown, setBreakdown] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const { disconnect } = useDisconnect();

  return (
    <HashRouter>
      <GatekeeperProvider>
        <Routes>
          {/*<Route path="/" element={<Layout />}></Route>*/}
          <Route
            element={
              <StakeLayout
                theme={theme}
                setTheme={setTheme}
                connectWallet={connectWallet}
                setConnectWallet={setConnectWallet}
                onClose={onClose}
                showSide={showSide}
                setShowSide={setShowSide}
                connected={isConnected}
                addy={address}
                showDetails={showDetails}
                setShowDetails={setShowDetails}
                setConnected={setConnected}
                action={action}
              />
            }
            path="/"
          >
            <Route
              element={
                <Defi
                  theme={theme}
                  setTheme={setTheme}
                  connectWallet={connectWallet}
                  setConnectWallet={setConnectWallet}
                  onClose={onClose}
                  connected={isConnected}
                />
              }
              path="/defi"
            />
            <Route
              element={
                <Vote
                  theme={theme}
                  setTheme={setTheme}
                  connectWallet={connectWallet}
                  setConnectWallet={setConnectWallet}
                  onClose={onClose}
                  connected={isConnected}
                  action={action}
                />
              }
              path="/vote"
            />
            <Route
              element={
                <Staking
                  theme={theme}
                  setTheme={setTheme}
                  connectWallet={connectWallet}
                  setConnectWallet={setConnectWallet}
                  onClose={onClose}
                  showSide={showSide}
                  setShowSide={setShowSide}
                  connected={isConnected}
                  showDetails={showDetails}
                  setShowDetails={setShowDetails}
                  setConnected={setConnected}
                  action={action}
                  addy={address}
                  chainId={chainId}
                  isLoading={isLoading}
                />
              }
              path="/"
            />

            <Route
              element={
                <Verify
                  theme={theme}
                  setTheme={setTheme}
                  connectWallet={connectWallet}
                  setConnectWallet={setConnectWallet}
                  onClose={onClose}
                  showSide={showSide}
                  connected={isConnected}
                  action={action}
                  disconnect={disconnect}
                  isLoading={isLoading}
                />
              }
              path="/verify"
            />
          </Route>
          {/*        <Route
          element={
            <LendLayout
              theme={theme}
              setTheme={setTheme}
              connectWallet={connectWallet}
              setConnectWallet={setConnectWallet}
              onClose={onClose}
              showSide={showSide}
              setShowSide={setShowSide}
              connected={isConnected}
              addy={address}
              breakdown={breakdown}
              setBreakdown={setBreakdown}
              showDetails={showDetails}
              setShowDetails={setShowDetails}
              setConnected={setConnected}
              action={action}
              isLoading={isLoading}
              chainId={chainId}
            />
          }
          path="/"
        >
          <Route
            element={
              <Bridge
                theme={theme}
                setTheme={setTheme}
                connectWallet={connectWallet}
                setConnectWallet={setConnectWallet}
                onClose={onClose}
                showSide={showSide}
                connected={isConnected}
              />
            }
            path="/bridge"
          />
          <Route
            element={
              <Rewards
                theme={theme}
                setTheme={setTheme}
                connectWallet={connectWallet}
                setConnectWallet={setConnectWallet}
                onClose={onClose}
                showSide={showSide}
                connected={isConnected}
                action={action}
              />
            }
            path="/rewards"
          />
          <Route
            element={
              <Market
                theme={theme}
                setTheme={setTheme}
                connectWallet={connectWallet}
                setConnectWallet={setConnectWallet}
                onClose={onClose}
                showSide={showSide}
                connected={isConnected}
                breakdown={breakdown}
                setBreakdown={setBreakdown}
                showDetails={showDetails}
                setShowDetails={setShowDetails}
                action={action}
                address={address}
                chainId={chainId}
              />
            }
            path="/"
          />
          <Route
            element={
              <LendOverview
                theme={theme}
                setTheme={setTheme}
                connectWallet={connectWallet}
                setConnectWallet={setConnectWallet}
                onClose={onClose}
                showSide={showSide}
                connected={isConnected}
              />
            }
            path="/overview"
          />
          </Route>*/}
        </Routes>
      </GatekeeperProvider>
    </HashRouter>
  );
}

export default App;
