const details = [
    {
      id: 1,
      t1: "Annual percentage rate",
      t2: "~5.55 %",
      more: true,
    },
    {
      id: 2,
      t1: "Exchange rate",
      t2: "1 AVAX ≈ 0.8683 sAVAX",
      more: true,
    },
    {
      id: 3,
      t1: "# of stakers",
      t2: "55,004",
      more: false,
    },
    {
      id: 4,
      t1: "Total AVAX staked",
      t2: "7,723,085 AVAX",
      more: false,
    },
    {
      id: 5,
      t1: "sAVAX Market cap",
      t2: "$228,469,699",
      more: false,
    },
    {
      id: 6,
      t1: "Unstaking cooldown period",
      t2: "15 days",
      more: true,
    },
    {
      id: 7,
      t1: "Redemption period",
      t2: "2 days",
      more: true,
    },
  ];

  export default details