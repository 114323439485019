
const as = [
    {
        id: 1,
        icon: "./images/as1.svg",
        name: "Definitive",
        link: "https://benqi.definitive.fi/"
    },
    {
        id: 2,
        icon: "./images/as2.png",
        name: "CIAN",
        link: "https://dapp.cian.app/"
    },

]

export default as