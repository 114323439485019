const pages = [
    {
      id: 1,
      title: "Liquid Staking",
      link: "/",
    },
    {
      id: 2,
      title: "Vote with veQI",
      link: "/vote",
    },
  ];

  export default pages